<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-toolbar color="color1 color1Text--text">
            <v-toolbar-title>Facebook Data Deletion Request</v-toolbar-title>
          </v-toolbar>
          <loading-bar :value="loading"></loading-bar>
          <v-expand-transition>
            <v-card-text v-if="ddr">
              <div>Your request was submitted on {{ddr.dtCreated | unixToLocalDt}}</div>
              <div class="my-3">Current Status: {{ddr.status}}</div>
              <div>For any question about your request please contact <a href="mailto:support@volleyballlife.com">support@volleyballlife.com</a></div>
            </v-card-text>
          </v-expand-transition>
          <v-expand-transition>
            <v-card-text v-if="!ddr && error">
              <div>We could not find your request</div>
              <div>Please contact <a href="mailto:support@volleyballlife.com">support@volleyballlife.com</a></div>
            </v-card-text>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      ddr: null,
      error: false
    }
  },
  methods: {
    getData () {
      this.loading = true
      this.$VBL.user.getFacebookDDR(this.$route.query.id)
        .then(r => { this.ddr = r.data })
        .catch(e => {
          console.log(e.response)
          this.error = true
        })
        .finally(f => { this.loading = false })
    }
  },
  created () {
    if (this.$route.query.id) this.getData()
  }
}
</script>
